import { setLivePosition, setNewPosition } from "actions/ui";
import classnames from "classnames";
import { CapitalizeObject } from "helpers/utility";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Trans } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import style from "./style.module.css";
class ProgressBar extends Component {
  constructor() {
    super();
    this.container = React.createRef();
    this.draggable = React.createRef();
    this.dragging = undefined;
    this.initialPosition = undefined;
  }

  async componentDidMount() {
    if (this.props.draggable) {
      const knob = this.draggable.current;
      knob.addEventListener("mousedown", this.mouseDownHandle.bind(this));
      document.addEventListener("mousemove", this.mouseMoveHandle.bind(this));
      document.addEventListener("mouseup", this.mouseUpHandle.bind(this));
      knob.addEventListener("touchstart", this.mouseDownHandle.bind(this));
      document.addEventListener("touchend", this.mouseUpHandle.bind(this));
      document.addEventListener("touchmove", this.mouseMoveHandle.bind(this));
    }
  }

  componentWillUnmount() {
    if (this.props.draggable) {
      const knob = this.draggable.current;
      if (document) {
        document.removeEventListener(
          "mousemove",
          this.mouseMoveHandle.bind(this)
        );
        document.removeEventListener("mouseup", this.mouseUpHandle.bind(this));
        document.removeEventListener("touchend", this.mouseUpHandle.bind(this));
        document.removeEventListener(
          "touchmove",
          this.mouseMoveHandle.bind(this)
        );
      }
      if (knob) {
        knob.removeEventListener("mousedown", this.mouseDownHandle.bind(this));
        knob.removeEventListener("touchstart", this.mouseDownHandle.bind(this));
      }
    }
  }

  mouseDownHandle(e) {
    if (!this.dragging) {
      this.dragging = true;
      this.initialPosition = e.x || e.touches[0].screenX;
    }
  }

  mouseUpHandle(event) {
    event.stopPropagation();
    const e = event.toElement || event.relatedTarget || event.target;
    if (!e || e.parentNode === this || e === this) {
      return;
    }
    // Handle click behaviour
    const eventPositionX = event.x || event.changedTouches[0].screenX;
    if (Math.abs(this.initialPosition - eventPositionX) < 10) {
      this.props.onClick(this.index, false);
    }
    // Cancel drag behaviour
    if (this.dragging) {
      this.dragging = false;
    }
  }

  mouseMoveHandle(e) {
    if (!this.dragging || !this.draggable) {
      return;
    }
    const { onClick, position, timeshift } = this.props;
    const containerPosition = this.container.current.getBoundingClientRect();
    const dragPosition = e.x || e.touches[0].screenX;
    const relativeDragPosition = dragPosition - containerPosition.x;
    const newPosition = (relativeDragPosition / containerPosition.width) * 100;
    if (timeshift > 0) {
      if (newPosition <= position) {
        this.props.dispatch(setNewPosition(newPosition));
        this.props.dispatch(setLivePosition(position));
        onClick(newPosition, false);
      }
    }
  }

  async handleOnClick(event) {
    event.stopPropagation();
    const { onClick, position, timeshift } = this.props;
    if (onClick && timeshift > 0) {
      const containerPosition = this.container.current.getBoundingClientRect();
      const clickPosition = event.clientX - containerPosition.x;
      const newPosition = (clickPosition / containerPosition.width) * 100;
      if (newPosition <= position) {
        await this.props.dispatch(setNewPosition(newPosition));
        await this.props.dispatch(setLivePosition(position));
        onClick(newPosition, true, position);
      }
    }
  }
  handleOnClickLiveIcon(event) {
    event.stopPropagation();
    const { onClick, info, timeshift } = this.props;
    if (onClick && timeshift > 0) {
      const duration =
        new Date(info.endTime).getTime() - new Date(info.startTime).getTime();
      const position =
        ((new Date().getTime() - new Date(info.startTime).getTime()) /
          duration) *
        100;
      this.props.dispatch(setNewPosition(position));
      this.props.dispatch(setLivePosition(position));
      onClick(position, true, position);
    }
  }

  render() {
    const {
      rootClassName,
      info,
      draggable,
      liveIndicator,
      pausePosition,
      timeshift,
      newPosition,
      livePosition,
    } = this.props;
    let currentProgress;
    const duration =
      new Date(info.endTime).getTime() - new Date(info.startTime).getTime();
    const position =
      ((new Date().getTime() - new Date(info.startTime).getTime()) / duration) *
      100;
    if (pausePosition) {
      currentProgress =
        newPosition + (position - livePosition) - (position - pausePosition);
    } else {
      currentProgress = newPosition + (position - livePosition);
    }
    let progressBarWidth = currentProgress * (100 / position);
    let draggableWidth = (position - currentProgress) * (100 / position);
    return (
      <div
        className={classnames(style.container, rootClassName, {
          [style.draggable]: draggable,
        })}
        ref={this.container}
      >
        {currentProgress >= 0 ? (
          <>
            <div
              className={style.progressBarContainer}
              style={{ width: `${position > 100 ? 100 : position}%` }}
              onClick={this.handleOnClick.bind(this)}
            >
              <div
                className={style.progressBar}
                style={{
                  width: `${progressBarWidth > 100 ? 100 : progressBarWidth}%`,
                  float: "left",
                }}
              />
              {draggable && (
                <>
                  <div
                    className={style.progressBarWhite}
                    style={{
                      width: `${draggableWidth > 100 ? 100 : draggableWidth}%`,
                      float: "right",
                    }}
                  />
                  <div style={{ clear: "both" }} />
                </>
              )}
            </div>
          </>
        ) : (
          <div
            className={style.progressBar}
            style={{ width: `${position > 100 ? 100 : position}%` }}
          />
        )}
        {liveIndicator && (
          <div
            className={
              draggable && currentProgress < position
                ? style.liveProgressWhite
                : style.liveProgress
            }
            style={{ left: `${position > 100 ? 100 : position}%` }}
            onClick={this.handleOnClickLiveIcon.bind(this)}
          >
            {CapitalizeObject(<Trans>live</Trans>)}
          </div>
        )}
        {draggable && currentProgress + 1 <= position && timeshift > 0 && (
          <>
            <div
              ref={this.draggable}
              className={style.draggableHandler}
              style={{
                left: `${currentProgress}%`,
              }}
            />
          </>
        )}
      </div>
    );
  }
}
export default compose(
  connect((state) => {
    return {
      newPosition: state.ui.newPosition,
      pausePosition: state.ui.pausePosition,
      livePosition: state.ui.livePosition,
    };
  })
)(ProgressBar);

ProgressBar.defaultProps = {
  position: 0,
  liveIndicator: false,
  draggable: false,
};

ProgressBar.propTypes = {
  position: PropTypes.number,
  draggable: PropTypes.bool,
  liveIndicator: PropTypes.bool,
};
