export default class VeygoApi extends window.GenericPlayerApi {
  constructor(isLive, playerInstance) {
    super();
    this.isLive = isLive;
    this.playerInstance = playerInstance;
  }

  getPlayerName() {
    return "Veygo"; // Player name
  }

  getVersion() {
    return this.playerInstance?.reporter?.getVersion(); // Player version
  }

  getOSName() {
    return this.playerInstance?.device?.deviceOs; // OS name
  }

  getDeviceType() {
    return "Web"; // Device type (stp, smartphone...)
  }

  // The method is used after a seek in order to get current position and compute the completion of the video.
  // This should return the current position in milliseconds.
  // In case of ad tracking compatibility, and if the current content is a LIVE content, the position has to be a position as a timestamp in millis.
  getCurrentPosition() {
    return this.playerInstance?.currentPlayer?.getTimeInfo()?.position || 0; // replace with your player method
  }

  // The method is necessary to display the completion time of the session.
  // The duration should be returned in milliseconds.
  // It must return 0 in case of LIVE content
  getTotalDuration() {
    return this.isLive ? 0 : this.playerInstance?.currentPlayer?.getTimeInfo()?.duration || 0; // replace with your player method
  }

  getCapabilities() {
    return {
      adTracking: true, // the player supports ad tracking, it means getCurrentPosition() returns a timestamp in case of LIVE contents
    };
  }
}
