import classnames from "classnames";
import { CapitalizeObject } from "helpers/utility";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Trans } from "react-i18next";

import style from "./style.module.css";

export default class ProgressBar extends Component {
  constructor() {
    super();
    this.container = React.createRef();
    this.draggable = React.createRef();
    this.dragging = undefined;
    this.initialPosition = undefined;
  }

  componentDidMount() {
    if (this.props.draggable) {
      const knob = this.draggable.current;
      knob.addEventListener("mousedown", this.mouseDownHandle.bind(this));
      document.addEventListener("mousemove", this.mouseMoveHandle.bind(this));
      document.addEventListener("mouseup", this.mouseUpHandle.bind(this));
      knob.addEventListener("touchstart", this.mouseDownHandle.bind(this));
      document.addEventListener("touchend", this.mouseUpHandle.bind(this));
      document.addEventListener("touchmove", this.mouseMoveHandle.bind(this));
    }
  }

  componentWillUnmount() {
    if (this.props.draggable) {
      const knob = this.draggable.current;
      knob.removeEventListener("mousedown", this.mouseDownHandle.bind(this));
      document.removeEventListener(
        "mousemove",
        this.mouseMoveHandle.bind(this)
      );
      document.removeEventListener("mouseup", this.mouseUpHandle.bind(this));
      knob.removeEventListener("touchstart", this.mouseDownHandle.bind(this));
      document.removeEventListener("touchend", this.mouseUpHandle.bind(this));
      document.removeEventListener(
        "touchmove",
        this.mouseMoveHandle.bind(this)
      );
    }
  }

  mouseDownHandle(e) {
    if (!this.dragging) {
      this.dragging = true;
      this.initialPosition = e.x || e.touches[0].screenX;
    }
  }

  mouseUpHandle(event) {
    event.stopPropagation();
    const e = event.toElement || event.relatedTarget || event.target;
    if (!e || e.parentNode === this || e === this) {
      return;
    }
    // Handle click behaviour
    const eventPositionX = event.x || event.changedTouches[0].screenX;
    if (Math.abs(this.initialPosition - eventPositionX) < 10) {
      this.props.onClick(this.index, false);
    }
    // Cancel drag behaviour
    if (this.dragging) {
      this.dragging = false;
    }
  }

  mouseMoveHandle(e) {
    if (!this.dragging || !this.draggable) {
      return;
    }
    const { onClick } = this.props;
    const containerPosition = this.container.current.getBoundingClientRect();
    const dragPosition = e.x || e.touches[0].screenX;
    const relativeDragPosition = dragPosition - containerPosition.x;
    const newPosition = (relativeDragPosition / containerPosition.width) * 100;
    onClick(newPosition, false);
  }

  handleOnClick(event) {
    event.stopPropagation();
    const { onClick } = this.props;
    if (onClick) {
      const containerPosition = this.container.current.getBoundingClientRect();
      const clickPosition = event.clientX - containerPosition.x;
      const newPosition = (clickPosition / containerPosition.width) * 100;
      onClick(newPosition, true);
    }
  }

  render() {
    const { rootClassName, position, draggable, liveIndicator } = this.props;
    return (
      <div
        className={classnames(style.container, rootClassName, {
          [style.draggable]: draggable,
        })}
        onClick={this.handleOnClick.bind(this)}
        ref={this.container}
      >
        <div className={style.progressBar} style={{ width: `${position}%` }} />
        {liveIndicator && (
          <div
            className={style.liveProgress}
            style={{ left: `calc(${position}% - 12px)` }}
          >
            {CapitalizeObject(<Trans>live</Trans>)}
          </div>
        )}
        {draggable && (
          <div
            ref={this.draggable}
            className={style.draggableHandler}
            style={{ left: `calc(${position}% - 12px)` }}
          />
        )}
      </div>
    );
  }
}

ProgressBar.defaultProps = {
  position: 0,
  liveIndicator: false,
  draggable: false,
};

ProgressBar.propTypes = {
  position: PropTypes.number,
  draggable: PropTypes.bool,
  liveIndicator: PropTypes.bool,
};
