import MenuItem from "components/MenuItem";
import consts from "consts/consts";
import routeConf from "consts/routes";
import React, { Component, Fragment } from "react";
import { Trans } from "react-i18next";

const ALWAYS_VISIBLE_MENU_ITEMS = ["home"];

export default class MenuListItems extends Component {
  render() {
    const { isOpened, isConnected, onClick, menu } = this.props;

    const visibleMenuItems = menu.filter((item) => {
      return (
        item.requireAuth === undefined ||
        (item.requireAuth === true && isConnected)
      );
    });

    return (
      <Fragment>
        {visibleMenuItems.map((item, index) => {
          const menuRoute = routeConf[0].routes.find((route) => {
            if (item.key === route.key) {
              if (
                ALWAYS_VISIBLE_MENU_ITEMS.includes(item.key) ||
                (consts.appModules &&
                  consts.appModules[route.key] &&
                  consts.appModules[route.key] === true)
              ) {
                return true;
              }
            }
            return false;
          });

          if (menuRoute) {
            if (consts.routes[item.key]?.externalURL) {
              return (
                <MenuItem
                  key={index}
                  to={consts.routes[item.key]?.externalURL}
                  icon={menuRoute.icon}
                  expanded={isOpened}
                  onClick={onClick}
                >
                  <Trans>{item.title || menuRoute.title}</Trans>
                </MenuItem>
              );
            }
            return (
              <MenuItem
                key={index}
                to={menuRoute.path}
                icon={menuRoute.icon}
                expanded={isOpened}
                onClick={onClick}
              >
                <Trans>{item.title || menuRoute.title}</Trans>
              </MenuItem>
            );
          } else {
            return undefined;
          }
        })}
      </Fragment>
    );
  }
}
