import consts from "../../consts/consts";
import actionTypes from "../consts/actionTypes";
import routes from "../consts/routes";
import { findObjectInArrayBy } from "../helpers/dataHelper";

export function appleAuth(accessToken) {
  const appleConf = findObjectInArrayBy(consts?.SSOSocials, "name", "apple");
  return (dispatch) => {
    return new Promise((resolve) => {
      const params = {
        code: accessToken,
        clientId: appleConf?.clientID,
        redirectUri: appleConf?.callbackAPI,
      };

      dispatch({
        request: {
          method: "POST",
          base: appleConf?.tucanoOAuth,
          url: routes.appleAuth,
          params: params,
        },
        onStart: () => {
          return {
            type: actionTypes.APPLE_AUTH_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type: actionTypes.APPLE_AUTH_SUCCESS,
            payload: payload,
          };
        },
        onError: (payload, meta) => {
          resolve(meta);
          return {
            type: actionTypes.APPLE_AUTH_ERROR,
            payload,
          };
        },
      });
    });
  };
}

export function resendVerificationMail(email) {
  return (dispatch) => {
    return new Promise((resolve) => {
      const params = { email };
      dispatch({
        request: {
          method: "POST",
          url: routes.resendEmailConfirmation,
          params: params,
        },
        onStart: () => {
          return {
            type: actionTypes.RESEND_EMAIL_CONFIRMATION_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type: actionTypes.RESEND_EMAIL_CONFIRMATION_SUCCESS,
            payload: payload,
          };
        },
        onError: (payload, meta) => {
          // Do something if the httpCode if >= 300
          resolve(meta);
          return {
            type: actionTypes.RESEND_EMAIL_CONFIRMATION_ERROR,
            payload,
          };
        },
      });
    });
  };
}

export function facebookAuth(accessToken) {
  const facebookConf = findObjectInArrayBy(consts?.SSOSocials, "name", "facebook");
  return (dispatch) => {
    return new Promise((resolve) => {
      const params = {
        code: accessToken,
        client_id: facebookConf?.clientID,
        redirectUri: facebookConf?.callbackAPI,
      };

      dispatch({
        request: {
          method: "POST",
          base: facebookConf?.tucanoOAuth,
          url: routes.facebookAuth,
          params: params,
        },
        onStart: () => {
          return {
            type: actionTypes.FACEBOOK_AUTH_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type: actionTypes.FACEBOOK_AUTH_SUCCESS,
            payload: payload,
          };
        },
        onError: (payload, meta) => {
          resolve(meta);
          return {
            type: actionTypes.FACEBOOK_AUTH_ERROR,
            payload,
          };
        },
      });
    });
  };
}

export function googleAuth(accessToken) {
  const googleConf = findObjectInArrayBy(consts?.SSOSocials, "name", "Google");
  return (dispatch) => {
    return new Promise((resolve) => {
      const params = {
        code: accessToken,
        redirectUri: googleConf?.callbackAPI,
        clientId: googleConf?.clientID,
      };

      dispatch({
        request: {
          method: "POST",
          base: googleConf?.tucanoOAuth,
          url: routes.googleAuth,
          params: params,
        },
        onStart: () => {
          return {
            type: actionTypes.GOOGLE_AUTH_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type: actionTypes.GOOGLE_AUTH_SUCCESS,
            payload: payload,
          };
        },
        onError: (payload, meta) => {
          resolve(meta);
          return {
            type: actionTypes.GOOGLE_AUTH_ERROR,
            payload,
          };
        },
      });
    });
  };
}

export function accountLogin(email, password, client_id, grant_type, deviceId) {
  return (dispatch) => {
    return new Promise((resolve) => {
      const params = { username: email, password, client_id, grant_type };
      if (deviceId) {
        params.deviceId = deviceId;
      }
      dispatch({
        request: {
          method: "POST",
          url: routes.graphQlLogin,
          params: params,
        },
        onStart: () => {
          return {
            type: actionTypes.LOGIN_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type: actionTypes.LOGIN_SUCCESS,
            payload: payload,
          };
        },
        onError: (payload, meta) => {
          // Do something if the httpCode if >= 300
          resolve(meta);
          return {
            type: actionTypes.LOGIN_ERROR,
            payload,
          };
        },
      });
    });
  };
}

export function accountLoginWebPlayer() {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        request: {
          method: "POST",
          url: routes.loginWebPlayer,
        },
        onStart: () => {
          return {
            type: actionTypes.LOGIN_WEB_PLAYER_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type: actionTypes.LOGIN_WEB_PLAYER_SUCCESS,
            payload: payload,
          };
        },
        onError: (payload, meta) => {
          // Do something if the httpCode if >= 300
          resolve(meta);
          return {
            type: actionTypes.LOGIN_WEB_PLAYER_ERROR,
            payload,
          };
        },
      });
    });
  };
}

export function casAddDevice(deviceId) {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        request: {
          method: "POST",
          url: routes.casAddDevice,
          params: { deviceUniqueId: deviceId },
        },
        onStart: () => {
          return {
            type: actionTypes.ADD_DEVICE_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type: actionTypes.ADD_DEVICE_SUCCESS,
            payload,
          };
        },
        onError: (payload, meta) => {
          // Do something if the httpCode if >= 300
          resolve(meta);
          return {
            type: actionTypes.ADD_DEVICE_ERROR,
            meta,
            payload,
          };
        },
      });
    });
  };
}

export function casAuth(deviceId, os, browser, version) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      if (!deviceId) {
        return reject("Missing deviceId params");
      }

      let type = "device_pc_other";
      if (os) {
        if (os.includes("Windows")) {
          type = "device_pc_windows";
        }
        if (os.includes("iOS") || os.includes("Mac OS")) {
          type = "device_pc_osx";
        }
        if (os.includes("Android OS") || os.includes("Linux") || os.includes("Chrome OS")) {
          type = "device_pc_linux";
        }
      }

      dispatch({
        request: {
          method: "POST",
          url: routes.casAuth,
          params: {
            casDeviceId: deviceId,
            name: "Browser " + browser + " " + version + " on " + os,
            type: type,
          },
        },
        onStart: () => {
          return {
            type: actionTypes.REGISTER_DEVICE_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type: actionTypes.REGISTER_DEVICE_SUCCESS,
            payload,
          };
        },
        onError: (payload, meta) => {
          // Do something if the httpCode if >= 300
          resolve(meta);
          return {
            type: actionTypes.REGISTER_DEVICE_ERROR,
            meta,
          };
        },
      });
    });
  };
}

export function casRemoveDevice(deviceId) {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        request: {
          method: "POST",
          url: routes.casRemoveDevice,
          params: { casDeviceId: deviceId },
        },
        onStart: () => {
          return {
            type: actionTypes.REMOVE_DEVICE_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type: actionTypes.REMOVE_DEVICE_SUCCESS,
            meta,
            payload,
          };
        },
        onError: (payload, meta) => {
          resolve(meta);
          return {
            type: actionTypes.REMOVE_DEVICE_ERROR,
            meta,
            payload,
          };
        },
      });
    });
  };
}

export function getDeviceList() {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        request: [
          {
            method: "POST",
            url: routes.casDeviceCount,
          },
          {
            method: "POST",
            url: routes.casAvailableDevice,
          },
        ],
        onStart: () => {
          return {
            type: actionTypes.GET_DEVICES_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type: actionTypes.GET_DEVICES_SUCCESS,
            payload,
          };
        },
        onError: (payload, meta) => {
          resolve(meta);
          return {
            type: actionTypes.GET_DEVICES_ERROR,
            meta,
          };
        },
      });
    });
  };
}

export function loginDevice() {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        request: {
          method: "POST",
          url: routes.loginDevice,
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
        },
        onError: (payload, meta) => {
          resolve(payload);
        },
      });
    });
  };
}

export function accountLogout() {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        request: {
          method: "POST",
          url: routes.logout,
        },
        onStart: () => {
          return {
            type: actionTypes.LOGOUT_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type: actionTypes.LOGOUT_SUCCESS,
            payload,
          };
        },
        onError: (payload, meta) => {
          resolve(meta);
          return {
            type: actionTypes.LOGOUT_ERROR,
            meta,
          };
        },
      });
    });
  };
}

export function getAccountDetails() {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        request: {
          method: "POST",
          url: routes.accountDetails,
        },
        onStart: () => {
          return {
            type: actionTypes.GET_ACCOUNT_DETAILS_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type: actionTypes.GET_ACCOUNT_DETAILS_SUCCESS,
            payload,
          };
        },
        onError: (payload, meta) => {
          resolve(meta);
          return {
            type: actionTypes.GET_ACCOUNT_DETAILS_ERROR,
            meta,
          };
        },
      });
    });
  };
}

export function changeDetails(params) {
  return (dispatch) => {
    return new Promise((resolve) => {
      // Duplicate email value as there is no confirmation on UI
      params.emailConfirm = params.email;
      dispatch({
        request: {
          method: "POST",
          url: routes.changeDetails,
          params: params,
        },
        onStart: () => {
          return {
            type: actionTypes.CHANGE_DETAILS_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type: actionTypes.CHANGE_DETAILS_SUCCESS,
            meta,
            payload,
          };
        },
        onError: (payload, meta) => {
          resolve(meta);
          return {
            type: actionTypes.CHANGE_DETAILS_ERROR,
            meta,
            payload,
          };
        },
      });
    });
  };
}

export function changeAddress(address) {
  const params = {};
  const changeAddressBody = [
    "name",
    "addressTitle",
    "addressType",
    "addressStreet",
    "addressSecondStreet",
    "addressThirdStreet",
    "addressNumber",
    "addressBox",
    "addressZip",
    "addressCity",
    "addressState",
    "addressCountry",
  ];

  for (let [key, value] of Object.entries(address)) {
    if (changeAddressBody.includes(key)) {
      params[key] = value;
    }
  }

  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        request: {
          method: "POST",
          url: routes.changeAddress,
          params: params,
        },
        onStart: () => {
          return {
            type: actionTypes.CHANGE_ADDRESS_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type: actionTypes.CHANGE_ADDRESS_SUCCESS,
            meta,
            payload,
          };
        },
        onError: (payload, meta) => {
          resolve(meta);
          return {
            type: actionTypes.CHANGE_ADDRESS_ERROR,
            meta,
            payload,
          };
        },
      });
    });
  };
}

export function updatePassword(email, oldPassword, password, passwordConfirm) {
  return (dispatch) => {
    return new Promise((resolve) => {
      const params = { email, oldPassword, password, passwordConfirm };
      dispatch({
        request: {
          method: "POST",
          url: routes.updatePassword,
          params: params,
        },
        onStart: () => {
          return {
            type: actionTypes.UPDATE_PASSWORD_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type: actionTypes.UPDATE_PASSWORD_SUCCESS,
            meta,
            payload,
          };
        },
        onError: (payload, meta) => {
          resolve(meta);
          return {
            type: actionTypes.UPDATE_PASSWORD_ERROR,
            meta,
            payload,
          };
        },
      });
    });
  };
}

export function getPasswordPolicy() {
  return (dispatch) => {
    return new Promise(async (resolve) => {
      dispatch({
        request: {
          method: "GET",
          url: routes.getPasswordPolicy,
        },
        onStart: () => {
          return {
            type: actionTypes.GET_PASSWORD_POLICY_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type: actionTypes.GET_PASSWORD_POLICY_SUCCESS,
            meta,
            payload,
          };
        },
        onError: (payload, meta) => {
          resolve(payload);
          return {
            type: actionTypes.GET_PASSWORD_POLICY_ERROR,
            meta,
            payload,
          };
        },
      });
    });
  };
}

export function askResetPassword(email) {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        request: {
          method: "POST",
          url: routes.askResetPassword,
          params: {
            email: email,
          },
        },
        onStart: () => {
          return {
            type: actionTypes.ASK_RESET_PASSWORD_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type: actionTypes.ASK_RESET_PASSWORD_SUCCESS,
            meta,
            payload,
          };
        },
        onError: (payload, meta) => {
          meta.error = payload;
          resolve(meta);
          return {
            type: actionTypes.ASK_RESET_PASSWORD_ERROR,
            meta,
            payload,
          };
        },
      });
    });
  };
}

export function resetAskResetPassword() {
  return {
    type: actionTypes.ASK_RESET_PASSWORD_RESET,
  };
}

export function resetPassword(email, emailConfirm, token, password, passwordConfirm) {
  return (dispatch) => {
    return new Promise((resolve) => {
      const params = { email, emailConfirm, token, password, passwordConfirm };
      dispatch({
        request: {
          method: "POST",
          requireCAT: false,
          url: routes.resetPassword,
          params: params,
        },
        onStart: () => {
          return {
            type: actionTypes.RESET_PASSWORD_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type: actionTypes.RESET_PASSWORD_SUCCESS,
            meta,
            payload,
          };
        },
        onError: (payload, meta) => {
          resolve(meta);
          return {
            type: actionTypes.RESET_PASSWORD_ERROR,
            meta,
            payload,
          };
        },
      });
    });
  };
}

export function resetResetPassword() {
  return {
    type: actionTypes.RESET_PASSWORD_RESET,
  };
}

export function getParentalCode() {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        request: {
          method: "POST",
          url: routes.adultCode,
        },
        onStart: () => {
          return {
            type: actionTypes.GET_PARENTAL_CODE_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type: actionTypes.GET_PARENTAL_CODE_SUCCESS,
            payload,
          };
        },
        onError: (payload, meta) => {
          resolve(meta);
          return {
            type: actionTypes.GET_PARENTAL_CODE_ERROR,
            meta,
          };
        },
      });
    });
  };
}

export function setAdultCode(adultCode) {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        request: {
          method: "POST",
          url: routes.setAdultCode,
          params: {
            adultCode: adultCode,
          },
        },
        onStart: () => {
          return {
            type: actionTypes.SET_ADULT_CODE_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type: actionTypes.SET_ADULT_CODE_SUCCESS,
            payload,
          };
        },
        onError: (payload, meta) => {
          resolve(meta);
          return {
            type: actionTypes.SET_ADULT_CODE_ERROR,
            meta,
          };
        },
      });
    });
  };
}
export function setPurchaseCode(purchaseCode) {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        request: {
          method: "POST",
          url: routes.setPurchaseCode,
          params: {
            purchaseCode: purchaseCode,
          },
        },
        onStart: () => {
          return {
            type: actionTypes.SET_PURCHASE_CODE_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type: actionTypes.SET_PURCHASE_CODE_SUCCESS,
            payload,
          };
        },
        onError: (payload, meta) => {
          resolve(meta);
          return {
            type: actionTypes.SET_PURCHASE_CODE_ERROR,
            meta,
          };
        },
      });
    });
  };
}
export function validateAdultCode(adultCode) {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        request: {
          method: "POST",
          url: routes.validateAdultCode,
          params: {
            parentalCode: adultCode,
          },
        },
        onStart: () => {
          return {
            type: actionTypes.VALIDATE_ADULT_CODE_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type: actionTypes.VALIDATE_ADULT_CODE_SUCCESS,
            meta,
            payload,
          };
        },
        onError: (payload, meta) => {
          resolve(payload);
          return {
            type: actionTypes.VALIDATE_ADULT_CODE_ERROR,
            meta,
            payload,
          };
        },
      });
    });
  };
}
export function validatePurchaseCode(purchaseCode) {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        request: {
          method: "POST",
          url: routes.validatePurchaseCode,
          params: {
            purchaseCode: purchaseCode,
          },
        },
        onStart: () => {
          return {
            type: actionTypes.VALIDATE_PURCHASE_CODE_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type: actionTypes.VALIDATE_PURCHASE_CODE_SUCCESS,
            meta,
            payload,
          };
        },
        onError: (payload, meta) => {
          resolve(payload);
          return {
            type: actionTypes.VALIDATE_PURCHASE_CODE_ERROR,
            meta,
            payload,
          };
        },
      });
    });
  };
}
export function validateAccount(email, token) {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        request: {
          method: "POST",
          url: routes.validateAccount,
          params: {
            email: email,
            token: token,
          },
        },
        onStart: () => {
          return {
            type: actionTypes.VALIDATE_ACCOUNT_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type: actionTypes.VALIDATE_ACCOUNT_SUCCESS,
            meta,
            payload,
          };
        },
        onError: (payload, meta) => {
          resolve(payload);
          return {
            type: actionTypes.VALIDATE_ACCOUNT_ERROR,
            meta,
            payload,
          };
        },
      });
    });
  };
}
export function getPurchaseCode() {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        request: {
          method: "POST",
          url: routes.PurchaseCode,
        },
        onStart: () => {
          return {
            type: actionTypes.GET_PURCHASE_CODE_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type: actionTypes.GET_PURCHASE_CODE_SUCCESS,
            payload,
          };
        },
        onError: (payload, meta) => {
          resolve(payload);
          return {
            type: actionTypes.GET_PURCHASE_CODE_ERROR,
            meta,
          };
        },
      });
    });
  };
}
export function requestExportAccount() {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        request: {
          method: "POST",
          url: routes.requestExportAccount,
        },
        onStart: () => {
          return {
            type: actionTypes.REQUEST_EXPORT_ACCOUNT_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type: actionTypes.REQUEST_EXPORT_ACCOUNT_SUCCESS,
            payload,
          };
        },
        onError: (payload, meta) => {
          resolve(meta);
          return {
            type: actionTypes.REQUEST_EXPORT_ACCOUNT_ERROR,
            meta,
          };
        },
      });
    });
  };
}

export function favoriteChannels() {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        request: {
          method: "POST",
          url: routes.favoriteChannels,
        },
        onStart: () => {
          return {
            type: actionTypes.FAVORITES_CHANNELS_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type: actionTypes.FAVORITES_CHANNELS_SUCCESS,
            payload,
          };
        },
        onError: (payload, meta) => {
          resolve(meta);
          return {
            type: actionTypes.FAVORITES_CHANNELS_ERROR,
            meta,
          };
        },
      });
    });
  };
}
export function addFavorite_Channel(channels) {
  const data = JSON.stringify(channels);
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        request: {
          method: "POST",
          url: routes.addFavoriteChannel,
          params: { favoriteChannels: data },
        },
        onStart: () => {
          return {
            type: actionTypes.FAVORITES_CHANNELS_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type: actionTypes.FAVORITES_CHANNELS_SUCCESS,
            payload,
          };
        },
        onError: (payload, meta) => {
          resolve(meta);
          return {
            type: actionTypes.FAVORITES_CHANNELS_ERROR,
            meta,
          };
        },
      });
    });
  };
}
export function favoriteAssets(languageId = "eng") {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        request: {
          method: "POST",
          url: routes.favoriteAssets,
          params: {
            languageId: languageId,
          },
        },
        onStart: () => {
          return {
            type: actionTypes.FAVORITES_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type: actionTypes.FAVORITES_SUCCESS,
            payload,
          };
        },
        onError: (payload, meta) => {
          resolve(meta);
          return {
            type: actionTypes.FAVORITES_ERROR,
            meta,
          };
        },
      });
    });
  };
}

export function addFavoriteAsset(id, isTVShow, isTVShowEpisode) {
  const params = {};
  if (isTVShow) {
    params.idCategory = id;
  } else if (isTVShowEpisode) {
    params.idCategory = id;
  } else {
    params.idAsset = id;
  }
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        request: {
          method: "POST",
          url: routes.addFavoriteAsset,
          params: params,
        },
        onStart: () => {
          return {
            type: actionTypes.ADD_FAVORITES_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type: actionTypes.ADD_FAVORITES_SUCCESS,
            payload,
          };
        },
        onError: (payload, meta) => {
          resolve(payload);
          return {
            type: actionTypes.ADD_FAVORITES_ERROR,
            payload,
          };
        },
      });
    });
  };
}

export function removeFavoriteAsset(id, isTVShow, isTVShowEpisode) {
  const params = {};
  if (isTVShow) {
    params.idCategory = id;
  } else if (isTVShowEpisode) {
    params.idCategory = id;
  } else {
    params.idAsset = id;
  }
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        request: {
          method: "POST",
          url: routes.removeFavoriteAsset,
          params: params,
        },
        onStart: () => {
          return {
            type: actionTypes.REMOVE_FAVORITES_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type: actionTypes.REMOVE_FAVORITES_SUCCESS,
            payload,
          };
        },
        onError: (payload, meta) => {
          resolve(payload);
          return {
            type: actionTypes.REMOVE_FAVORITES_ERROR,
            payload,
          };
        },
      });
    });
  };
}

export function changeScoreAsset(id, score, isTVShow, isTVShowEpisode) {
  const params = {};
  if (isTVShow) {
    params.idCategory = id;
  } else if (isTVShowEpisode) {
    params.idCategory = id;
  } else {
    params.idAsset = id;
  }

  params.score = score;

  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        request: {
          method: "POST",
          url: routes.changeScoreAsset,
          params: params,
        },
        onStart: () => {
          return {
            type: actionTypes.CHANGE_SCORE_ASSET_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type: actionTypes.CHANGE_SCORE_ASSET_SUCCESS,
            payload,
          };
        },
        onError: (payload, meta) => {
          resolve(meta);
          return {
            type: actionTypes.CHANGE_SCORE_ASSET_ERROR,
            meta,
          };
        },
      });
    });
  };
}

export function listInvoices(limit, offset, from, to, languageId = "eng") {
  const params = {};
  if (limit) {
    params.limit = limit;
  }
  if (offset) {
    params.offset = offset;
  }
  if (from) {
    params.from = from;
  }
  if (to) {
    params.to = to;
  }
  if (languageId) {
    params.languageId = languageId;
  }
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        request: {
          method: "POST",
          url: routes.listInvoices,
          params: params,
        },
        onStart: () => {
          return {
            type: actionTypes.GET_INVOICES_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type: actionTypes.GET_INVOICES_SUCCESS,
            payload,
          };
        },
        onError: (payload, meta) => {
          resolve(meta);
          return {
            type: actionTypes.GET_INVOICES_ERROR,
            meta,
          };
        },
      });
    });
  };
}

export function getProfile() {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        request: {
          method: "GET",
          url: routes.profile,
        },
        onStart: () => {
          return {
            type: actionTypes.PROFILE_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type: actionTypes.PROFILE_SUCCESS,
            meta,
            payload,
          };
        },
        onError: (payload, meta) => {
          resolve(payload);
          return {
            type: actionTypes.PROFILE_ERROR,
            meta,
            payload,
          };
        },
      });
    });
  };
}
export function getAvatars() {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        request: {
          method: "GET",
          url: routes.avatars,
        },
        onStart: () => {
          return {
            type: actionTypes.AVATARS_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type: actionTypes.AVATARS_SUCCESS,
            meta,
            payload,
          };
        },
        onError: (payload, meta) => {
          resolve(payload);
          return {
            type: actionTypes.AVATARS_ERROR,
            meta,
            payload,
          };
        },
      });
    });
  };
}
export function createOrUpdateProfile(params) {
  let url = routes.profile;
  let method = "POST";
  if (params.id) {
    url = url + "/" + params.id;
    method = "PUT";
  }

  //const parameters = new FormData();
  // if (params.name) {
  //   parameters.append("name", params.name);
  // }
  // if (params.avatarId) {
  //   parameters.append("avatarId", params.avatarId);
  // }
  if (!params.picture || method === "PUT") {
    delete params.picture;
  }
  if (!params.avatarId) {
    delete params.avatarId;
    if (method === "POST" && params.picture) {
      if (!Number.isInteger(parseInt(params.picture))) {
        let arr = params.picture.split(","),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);

        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        params.pictures = new File([u8arr], this.fileName, { type: mime });
        // parameters.append(
        //   "picture",
        //   new File([u8arr], this.fileName, {
        //     type: mime,
        //   })
        // );
      }
    }
  }
  // if (params.language) {
  //   parameters.append("language", params.language);
  // }
  // if (params.maximumMoralityLevel) {
  //   parameters.append("maximumMoralityLevel", params.maximumMoralityLevel);
  // }
  // if (params.kidProfile) {
  //   parameters.append("kidProfile", params.kidProfile);
  // }
  // if (params.password) {
  //   parameters.append("password", params.password);
  // }
  // if (params.newPassword) {
  //   parameters.append("newPassword", params.newPassword);
  // }
  // if (params.default) {
  //   parameters.append("default", params.default);
  // }
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      if (!params.name) {
        return reject("Missing name params");
      }

      if (!params.language) {
        return reject("Missing lanaguage params");
      }

      dispatch({
        request: {
          method: method,
          url: url,
          params: params,
        },
        onStart: () => {
          return {
            type: actionTypes[method + "_PROFILE_PENDING"],
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type: actionTypes[method + "_PROFILE_SUCCESS"],
            meta,
            payload,
          };
        },
        onError: (payload, meta) => {
          resolve(payload);
          return {
            type: actionTypes[method + "_PROFILE_ERROR"],
            meta,
            payload,
          };
        },
      });
    });
  };
}

export function deleteProfile(idProfile) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      if (!idProfile) {
        return reject("Missing idProfile params");
      }

      dispatch({
        request: {
          method: "DELETE",
          url: routes.profile + "/" + idProfile,
        },
        onStart: () => {
          return {
            type: actionTypes.DELETE_PROFILE_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type: actionTypes.DELETE_PROFILE_SUCCESS,
            meta,
            payload,
          };
        },
        onError: (payload, meta) => {
          resolve(payload);
          return {
            type: actionTypes.DELETE_PROFILE_ERROR,
            meta,
            payload,
          };
        },
      });
    });
  };
}

export function getActiveProfile() {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        request: {
          method: "GET",
          url: routes.profile + "/active",
        },
        onStart: () => {
          return {
            type: actionTypes.PROFILE_ACTIVE_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type: actionTypes.PROFILE_ACTIVE_SUCCESS,
            meta,
            payload,
          };
        },
        onError: (payload, meta) => {
          resolve(payload);
          return {
            type: actionTypes.PROFILE_ACTIVE_ERROR,
            meta,
            payload,
          };
        },
      });
    });
  };
}

export function setActiveProfile(idProfile, password) {
  const params = {
    idProfile: idProfile,
  };

  if (password) {
    params.password = password;
  }

  return (dispatch) => {
    return new Promise((resolve, reject) => {
      if (!idProfile) {
        return reject("Missing idProfile params");
      }

      dispatch({
        request: {
          method: "POST",
          url: routes.profile + "/active",
          params: params,
        },
        onStart: () => {
          return {
            type: actionTypes.SET_PROFILE_ACTIVE_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type: actionTypes.SET_PROFILE_ACTIVE_SUCCESS,
            meta,
            payload,
          };
        },
        onError: (payload, meta) => {
          resolve(payload);
          return {
            type: actionTypes.SET_PROFILE_ACTIVE_ERROR,
            meta,
            payload,
          };
        },
      });
    });
  };
}

export function setSignupStep(onSignupSteps) {
  return {
    type: actionTypes.SET_ON_SIGNUP_STEPS,
    onSignupSteps,
  };
}

export function checkPassword(email, password) {
  return (dispatch) => {
    return new Promise((resolve) => {
      const params = { email, password };
      dispatch({
        request: {
          method: "POST",
          url: routes.login,
          params: params,
        },
        onStart: () => {
          return {
            type: actionTypes.CHECK_PASSWORD_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type: actionTypes.CHECK_PASSWORD_SUCCESS,
            payload: payload,
          };
        },
        onError: (payload, meta) => {
          resolve(payload);
          return {
            type: actionTypes.CHECK_PASSWORD_ERROR,
            payload,
          };
        },
      });
    });
  };
}

export function deleteMyAccount() {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        request: {
          method: "POST",
          url: routes.anonymizeMe,
        },
        onStart: () => {
          return {
            type: actionTypes.DELETE_ACCOUNT_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type: actionTypes.DELETE_ACCOUNT_SUCCESS,
            payload: payload,
          };
        },
        onError: (payload, meta) => {
          resolve(payload);
          return {
            type: actionTypes.DELETE_ACCOUNT_ERROR,
            payload,
          };
        },
      });
    });
  };
}

export function syncFromTve(token, providerId, tveAdapter) {
  return (dispatch) => {
    return new Promise((resolve) => {
      const params = {
        providerId,
        tveAdapter,
        tveParams: "", // JSON.stringify({}),
        userId: "notUserId",
      };
      const headers = {
        "X-AN-WebService-CustomerAuthToken": token,
      };
      dispatch({
        request: {
          method: "POST",
          url: routes.syncFromTve,
          params,
          headers,
        },
        onStart: () => {
          return {
            type: actionTypes.SYNC_FROM_TVE_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type: actionTypes.SYNC_FROM_TVE_SUCCESS,
            payload: payload,
          };
        },
        onError: (payload, meta) => {
          resolve(meta);
          return {
            type: actionTypes.SYNC_FROM_TVE_ERROR,
            payload,
          };
        },
      });
    });
  };
}

export function getExportAccount(idJobQueue) {
  return (dispatch) => {
    return new Promise((resolve) => {
      const params = { idJobQueue };
      dispatch({
        request: {
          method: "POST",
          url: routes.getExportAccount,
          params: params,
        },
        onStart: () => {
          return {
            type: actionTypes.GET_EXPORT_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type: actionTypes.GET_EXPORT_SUCCESS,
            payload: payload,
          };
        },
        onError: (payload, meta) => {
          resolve(payload);
          return {
            type: actionTypes.GET_EXPORT_ERROR,
            payload,
          };
        },
      });
    });
  };
}

export function loginByPairing(code, deviceId) {
  return (dispatch) => {
    return new Promise((resolve) => {
      const params = {
        code,
        deviceId,
      };
      dispatch({
        request: {
          method: "POST",
          url: routes.loginByPairing,
          params,
        },
        onStart: () => {
          return {
            type: actionTypes.LOGIN_BY_PAIRING_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type: actionTypes.LOGIN_BY_PAIRING_SUCCESS,
            payload: payload,
          };
        },
        onError: (payload, meta) => {
          resolve(payload);
          return {
            type: actionTypes.LOGIN_BY_PAIRING_ERROR,
            payload,
          };
        },
      });
    });
  };
}
