import { addToast } from "actions/ui";
import classnames from "classnames";
import ErrorMessage from "components/ErrorMessage";
import FormProfile from "components/FormProfile";
import Grid from "components/Grid";
import AddMoreIcon from "components/Icons/AddMore";
import Image, { ImageRatio, PlaceholderType } from "components/Image";
import Loader from "components/Loader";
import Modal from "components/Modal";
import consts from "consts/consts";
import PageAbstract from "containers/PageAbstract";
import { getFromLocal, saveInLocal } from "helpers/localStorage";
import React, { Fragment } from "react";
import { Trans, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose } from "redux";
import { TucanoActions, TucanoSelectors } from "web-api/main";

import routes from "../../web-api/consts/routes";
import style from "./style.module.css";

class Profile extends PageAbstract {
  state = {
    data: {
      name: "",
      kidProfile: false,
      maximumMoralityLevel: 0,
      language: getFromLocal("languageId"),
      password: false,
    },
    show: false,
    edit: false,
    languageIdMapping: consts.languageIdMapping,
  };

  errorAPICreateProfile = {
    "-3054": "Limit of profiles reached",
    "-30": "Profile name already exists",
  };

  getErrorAPI(api, code) {
    let error = "An error occured,Please retry later";

    switch (api) {
      case "createProfile":
        if (this.errorAPICreateProfile[code]) {
          error = this.errorAPICreateProfile[code];
        }
        break;
      default:
        break;
    }

    return error;
  }
  static fetch(dispatch, isConnected) {
    if (isConnected) {
      dispatch(TucanoActions.getAvatars());
    }
  }
  componentDidMount() {
    if (!this.props.isConnected) {
      this.props.history.push("?login=1");
    } else {
      Profile.fetch(this.props.dispatch, this.props.isConnected);
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps !== this.props && this.props.userInfo) {
      this.setState((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          language: this.props.userInfo.languageId,
        },
      }));
    }
  }

  selectProfile(item) {
    if (this.props.selectProfile) {
      if (this.props.shouldUpdateLanguage) {
        saveInLocal("languageId", item.language);
        this.props.i18n.changeLanguage(
          this.state.languageIdMapping[item.language]
        );
      }
      this.props.selectProfile(item);
    }
  }

  addProfile() {
    let language =
      this.props.userInfo && this.props.userInfo.languageId !== undefined
        ? this.props.userInfo.languageId
        : getFromLocal("languageId");
    this.setState((prevState) => ({
      ...prevState,
      data: {
        name: "",
        kidProfile: false,
        maximumMoralityLevel: 0,
        language,
      },
      edit: false,
      show: true,
    }));
  }

  showModal() {
    this.setState({ show: true });
  }

  hideModal() {
    this.setState({ show: false });
  }

  handleCreateProfile(params) {
    const { t } = this.props;

    this.props
      .dispatch(TucanoActions.createOrUpdateProfile(params))
      .then(() => {
        const { dataOnCreateProfile } = this.props;

        if (dataOnCreateProfile && !dataOnCreateProfile.code) {
          this.props.dispatch(
            addToast({
              text: t("Your profile has been successfully created"),
              className: "primary",
              duration: 6000,
            })
          );
          this.hideModal();
          this.props.dispatch(TucanoActions.getProfile());
          let language =
            this.props.userInfo && this.props.userInfo.languageId !== undefined
              ? this.props.userInfo.languageId
              : getFromLocal("languageId");
          this.setState((prevState) => ({
            ...prevState,
            data: {
              name: "",
              kidProfile: false,
              maximumMoralityLevel: 0,
              language,
              password: false,
            },
          }));
        }

        if (dataOnCreateProfile && dataOnCreateProfile.code) {
          this.props.dispatch(
            addToast({
              text: t(
                this.getErrorAPI("createProfile", dataOnCreateProfile.code)
              ),
              className: "error",
              duration: 6000,
            })
          );
        }
      })
      .catch((error) => {
        this.props.dispatch(
          addToast({
            text: t(this.getErrorAPI("createProfile", error)),
            className: "error",
            duration: 6000,
          })
        );
      });
  }

  render() {
    const { show } = this.state;
    const {
      profile,
      activeProfile,
      isLoading,
      editable,
      rootClassName,
      avatars,
      t,
    } = this.props;

    const width = 80;
    const height = 80;
    const direction = getFromLocal("layoutDirection");

    return (
      <Fragment>
        {isLoading && <Loader centered={true} />}
        {!isLoading && (
          <Fragment>
            <div className={style.root}>
              {profile && profile.length > 0 ? (
                <Grid rootClassName={style.grid}>
                  {profile &&
                    profile.map((item, index) => (
                      <div
                        key={index}
                        className={classnames(style.profiles, rootClassName, {
                          [style.defaultProfile]:
                            activeProfile && activeProfile.id === item.getId(),
                        })}
                        onClick={this.selectProfile.bind(this, item)}
                      >
                        <div className={style.headerProfile}>
                          {item.getPicture() === null ? (
                            <div className={style.profilImage} />
                          ) : (
                            <Image
                              rootClassName={style.profilImage}
                              width={width}
                              height={height}
                              src={`${consts.image_hostname}${
                                routes.imgdata
                              }?type=profile&objectId=${item.getPicture()}&format_w=${80}&format_h=${80}&languageId=${getFromLocal(
                                "languageId"
                              )}`}
                              ratio={ImageRatio.CIRCLE}
                              placeholder={PlaceholderType.PROFILE}
                            />
                          )}
                          {item.default === true && (
                            <div className={style.mainProfile}>
                              <span>
                                <Trans>Main</Trans>
                              </span>
                            </div>
                          )}
                        </div>
                        <div
                          className={classnames(style.context, {
                            [style.contextRTL]: direction === "rtl",
                          })}
                        >
                          <div className={style.username}>{item.getName()}</div>
                          <div className={style.maximumMoralityLevel}>
                            {item.getMaximumMoralityLevel() &&
                            item.getMaximumMoralityLevel() > 0 ? (
                              <span>
                                <Trans>Access content up to</Trans>{" "}
                                {item.getMaximumMoralityLevel()}{" "}
                                <Trans>
                                  {item.getMaximumMoralityLevel() === 1
                                    ? "Year"
                                    : "Years"}
                                </Trans>
                              </span>
                            ) : (
                              <span>
                                <Trans>Access to all content</Trans>
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  {editable !== false && (
                    <div
                      className={classnames(
                        style.profiles,
                        style.addProfile,
                        rootClassName
                      )}
                      onClick={this.addProfile.bind(this)}
                    >
                      <AddMoreIcon className={style.iconAddMore} />
                    </div>
                  )}
                </Grid>
              ) : (
                <ErrorMessage
                  rootClassName={style.errorMessage}
                  message={t("No profile")}
                />
              )}
            </div>
          </Fragment>
        )}
        {show && (
          <Modal
            leftContent={{ title: t("Create a profile") }}
            rightContent={{ canDismiss: this.hideModal.bind(this) }}
            rootClassName={style.modal}
            overlay={true}
          >
            <FormProfile
              onSubmitClick={this.handleCreateProfile.bind(this)}
              data={this.state.data}
              avatars={avatars}
            />
          </Modal>
        )}
      </Fragment>
    );
  }
}

export default withRouter(
  compose(
    connect((state) => {
      return {
        isConnected: state.session.customerAuthToken !== undefined,
        viewport: state.ui.viewport,
        userInfo: TucanoSelectors.getUserInfo(state),
        isLoading:
          state.account.profile.loading &&
          state.account.getActiveProfile.loading,
        dataOnCreateProfile: state.account.createProfile.data,
        dataOnSetActiveProfile: state.account.setActiveProfile.data,
        profile: TucanoSelectors.getProfile(state),
        activeProfile: TucanoSelectors.getActiveProfile(state),
        avatars: TucanoSelectors.getAvatars(state),
      };
    }),
    withTranslation()
  )(Profile)
);
