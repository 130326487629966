import { addToast } from "actions/ui";
import Button, { ButtonType } from "components/Button";
import Input, { InputType } from "components/Input";
import Loader from "components/Loader";
import React, { Component, Fragment } from "react";
import { Trans, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { TucanoActions } from "web-api/main";

import style from "./style.module.css";

const ADULT_CODE_REGEXP = /^[0-9]*$/;

/* WARNING :
 * adultCode must be handled as a string to work with code that starts with zero
 */
class FormChangePurchaseCode extends Component {
  state = {
    data: {
      currentPurchaseCode: "",
      newPurchaseCode: "",
      confirmNewPurchaseCode: "",
    },
    error: {
      currentPurchaseCode: "",
      newPurchaseCode: "",
      confirmNewPurchaseCode: "",
    },
    focus: {
      currentPurchaseCode: false,
      newPurchaseCode: false,
      confirmNewPurchaseCode: false,
    },
    digitNumberLength: 4,
  };

  constructor(props) {
    super(props);
    this.handleKeyPressed = this.handleKeyPressed.bind(this);
  }
  errorAPI = {
    "-155": "missing parameter purchaseCode",
    "-2371": "wrong parameter previousPurchaseCode",
    "-2321": "wrong parameter previousPurchaseCode",
  };

  getErrorAPI(code) {
    if (this.errorAPI[code]) {
      return this.errorAPI[code];
    } else {
      return "An error occured,Please retry later";
    }
  }
  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyPressed, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyPressed, false);
  }

  handleSubmit() {
    const { t } = this.props;
    this.props
      .dispatch(
        TucanoActions.validatePurchaseCode(this.state.data.currentPurchaseCode)
      )
      .then((result) => {
        if (!result.code) {
          this.props
            .dispatch(
              TucanoActions.setPurchaseCode(this.state.data.newPurchaseCode)
            )
            .then((res) => {
              if (!result.code) {
                this.props.dispatch(
                  addToast({
                    text: t("Your purchase code has been successfully updated"),
                    className: "primary",
                    duration: 6000,
                  })
                );
                if (this.props.closeModal) {
                  this.props.closeModal();
                }
              } else {
                const error = { ...this.state.error };
                error.newPurchaseCode = this.getErrorAPI(res.code);
                this.setState({ error: error });
                this.props.dispatch(
                  addToast({
                    text: t(
                      this.getErrorAPI(
                        "updatePurchaseCode",
                        error.newPurchaseCode
                      )
                    ),
                    className: "error",
                    duration: 6000,
                  })
                );
              }
            });
        } else {
          const error = { ...this.state.error };
          error.currentPurchaseCode = this.getErrorAPI(result.code);
          this.setState({ error: error });
          this.props.dispatch(
            addToast({
              text: t(
                this.getErrorAPI(
                  "updatePurchaseCode",
                  error.currentPurchaseCode
                )
              ),
              className: "error",
              duration: 6000,
            })
          );
        }
      });
  }

  handleKeyPressed(event) {
    if (event.key === "Enter") {
      if (
        this.state.focus.currentPurchaseCode &&
        this.state.focus.newPurchaseCode &&
        this.state.focus.confirmNewPurchaseCode &&
        this.state.error.currentPurchaseCode === "" &&
        this.state.error.newPurchaseCode === "" &&
        this.state.error.confirmNewPurchaseCode === "" &&
        this.state.data.newPurchaseCode ===
          this.state.data.confirmNewPurchaseCode
      ) {
        this.handleSubmit();
      }
    }
  }

  handleNewPurchaseCodeChange(key, value) {
    const updatedState = this.state;
    const { t } = this.props;
    updatedState["data"][key] = value;

    if (
      ADULT_CODE_REGEXP.test(value) &&
      value.length === this.state.digitNumberLength
    ) {
      updatedState["error"][key] = "";
    } else {
      updatedState["error"][key] = t("Purchase code is a 4 digit number");
    }
    updatedState["focus"][key] = true;
    this.setState(updatedState);
  }
  render() {
    const { isLoading, isUpdating, t } = this.props;
    return (
      <Fragment>
        {isLoading && (
          <div className={style.loader}>
            <Loader />{" "}
          </div>
        )}
        {!isLoading && (
          <Fragment>
            <div className={style.root}>
              <div className={style.form}>
                <div className={style.container}>
                  <label className={style.title}>
                    <Trans>Enter current purchase code</Trans>
                  </label>
                  <Input
                    type={InputType.PASSWORD}
                    onChange={(value) =>
                      this.handleNewPurchaseCodeChange(
                        "currentPurchaseCode",
                        value
                      )
                    }
                    value={this.state.data.currentPurchaseCode}
                  />
                  {this.state.error.currentPurchaseCode && (
                    <p className={style.error}>
                      <Trans>{this.state.error.currentPurchaseCode}</Trans>
                    </p>
                  )}
                </div>
                <div className={style.container}>
                  <label className={style.title}>
                    <Trans>New purchase code</Trans>
                  </label>
                  <Input
                    type={InputType.PASSWORD}
                    onChange={(value) =>
                      this.handleNewPurchaseCodeChange("newPurchaseCode", value)
                    }
                    value={this.state.data.newPurchaseCode}
                  />
                  {this.state.error.newPurchaseCode && (
                    <p className={style.error}>
                      <Trans>{this.state.error.newPurchaseCode}</Trans>
                    </p>
                  )}
                </div>
                <div className={style.container}>
                  <label className={style.title}>
                    <Trans>Confirm new purchase code</Trans>
                  </label>
                  <Input
                    type={InputType.PASSWORD}
                    onChange={(value) =>
                      this.handleNewPurchaseCodeChange(
                        "confirmNewPurchaseCode",
                        value
                      )
                    }
                    value={this.state.data.confirmNewPurchaseCode}
                  />
                  {this.state.error.confirmNewPurchaseCode && (
                    <p className={style.error}>
                      <Trans>{this.state.error.confirmNewPurchaseCode}</Trans>
                    </p>
                  )}
                  {this.state.data.confirmNewPurchaseCode &&
                    this.state.data.newPurchaseCode !==
                      this.state.data.confirmNewPurchaseCode && (
                      <p className={style.error}>
                        <Trans>The purchase codes are different</Trans>
                      </p>
                    )}
                </div>
              </div>
              <div>
                {isUpdating && (
                  <Button
                    rootClassName={style.submitButton}
                    type={ButtonType.LOADING}
                  >
                    <Trans>Change purchase code</Trans>
                  </Button>
                )}
                {!isUpdating && (
                  <Button
                    rootClassName={style.submitButton}
                    type={
                      !this.state.focus.currentPurchaseCode ||
                      !this.state.focus.newPurchaseCode ||
                      !this.state.focus.confirmNewPurchaseCode ||
                      this.state.error.currentPurchaseCode !== "" ||
                      this.state.error.newPurchaseCode !== "" ||
                      this.state.error.confirmNewPurchaseCode !== "" ||
                      this.state.data.newPurchaseCode !==
                        this.state.data.confirmNewPurchaseCode
                        ? ButtonType.DISABLED
                        : ButtonType.NORMAL
                    }
                    onClick={this.handleSubmit.bind(this)}
                  >
                    <Trans>Change purchase code</Trans>
                  </Button>
                )}
                <div className={style.labelContainer}>
                  {/* <div>
                    <p className={style.forgottenCodeLabel}>
                      <Trans>Forgotten your purchase code?</Trans>
                      <span className={style.resetLabel}>
                        <Trans>Reset it</Trans>
                      </span>
                    </p>
                  </div> */}
                  <div className={style.regularLabel}>
                    {t("defaultPurchaseCode")}
                  </div>
                </div>
              </div>
            </div>
          </Fragment>
        )}
      </Fragment>
    );
  }
}
export default compose(
  connect((state) => {
    return {
      isLoading: state.account.purchaseCode.loading,
      isUpdating: state.account.purchaseCode.updating,
      isUpdated: state.account.purchaseCode.updated,
      isCodeValid: state.account.validatePurchaseCode.data,
    };
  }),
  withTranslation()
)(FormChangePurchaseCode);
