import classnames from "classnames";
import CatchupIcon from "components/Icons/CatchUp";
import RecordActions from "components/RecordActions";
import consts from "consts/consts";
import { format, isPast } from "date-fns";
import { getFromLocal } from "helpers/localStorage";
import { isLiveOrUpComing } from "helpers/time";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { TucanoActions } from "web-api/main";

import style from "./style.module.css";
class EPGGridText extends Component {
  onClickStartOver = (event) => {
    const { channel, dispatch } = this.props;
    event.stopPropagation();
    event.preventDefault();
    const url = consts.routes.playerlive.url.replace(":channelId", channel.id);
    dispatch(TucanoActions.restartPlayerLive());
    this.props.history.push(url);
  };
  render() {
    const { epg, channel, isConnected, rootClassName, accountStatus } =
      this.props;

    const now = new Date().getTime();
    const timeShiftDuration = channel.timeshift;
    const isStartoverAllowed =
      now - epg.getStartTime().getTime() < timeShiftDuration;
    const isCurrentProgram =
      now > epg.getStartTime().getTime() && now < epg.getEndTime().getTime();
    let displayCatchupIcon = false;
    let displayStartOverIcon = false;
    const direction = getFromLocal("layoutDirection");
    if (
      (channel.isCatchupChannel === true ||
        (isConnected && !channel.userHasAccess)) &&
      epg.getStartTime().getTime() > now - channel.getCatchupValue() &&
      epg.isCatchupAvailable() &&
      isPast(epg.getEndTime()) &&
      accountStatus &&
      accountStatus.toLowerCase() !== "suspended"
    ) {
      displayCatchupIcon = true;
    }
    if (
      channel.startOver === true &&
      isStartoverAllowed &&
      isCurrentProgram &&
      accountStatus &&
      accountStatus.toLowerCase() !== "suspended"
    ) {
      displayCatchupIcon = false;
      displayStartOverIcon = true;
    }
    return (
      <div className={style.rootContainer} onClick={this.props.onClick}>
        <div
          className={classnames(rootClassName, style.textContainer, {
            [style.direction]: direction.toLowerCase() === "rtl",
          })}
        >
          <div style={{ direction: "ltr" }}>
            <span className={style.startTime}>
              {format(epg.getStartTime(), "HH:mm")}
            </span>
            <span className={style.duration}>{epg.getHumanDuration()}</span>
          </div>
          {displayCatchupIcon && <CatchupIcon className={style.catchupIcon} />}
          {displayStartOverIcon && (
            <div
              className={style.startOverContainer}
              onClick={this.onClickStartOver}
            >
              <CatchupIcon className={style.catchupiIcon} />
            </div>
          )}
          <p className={style.title}>{epg.getTitle()}</p>
          <p className={style.kind}>
            {epg.getGenre()}{" "}
            {!!epg.getMoralityLevel() &&
              (Number.isInteger(Number(epg.getMoralityLevel())) &&
              Number(epg.getMoralityLevel()) > 0 ? (
                <span className={style.moralityLevel}>
                  | {epg.getMoralityLevel()}+
                </span>
              ) : (
                <span className={style.moralityLevel}>
                  | {epg.getMoralityLevel()}
                </span>
              ))}
          </p>
          {((isLiveOrUpComing(
            epg.getStartTime().getTime(),
            epg.getEndTime().getTime()
          ) &&
            epg.npvrEnabled) ||
            epg.getRecordStatus() !== "Record") && (
            <div className={style.actionNpvr}>
              <RecordActions
                type={epg.getRecordStatus()}
                epg={epg}
                channel={channel}
                refreshPrograms={this.props.refreshPrograms}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

EPGGridText.defaultProps = {
  isConnected: false,
};

EPGGridText.propTypes = {
  isConnected: PropTypes.bool,
};
export default withRouter(
  connect((state, _props) => ({
    accountStatus: state.account.user?.data?.status,
  }))(EPGGridText)
);
