export default class IngenicoModel {
  //region Action

  fillForm(data, node) {
    node.innerHTML = "";

    let f = node;

    f.action = data["action"];
    f.method = "POST";

    if (data && data["data"]) {
      Object.entries(data["data"]).forEach(([key, value]) => {
        let field = document.createElement("input");
        field.type = "hidden";
        field.name = key;
        field.value = value;

        f.appendChild(field);
      });
    }

    let fieldS = document.createElement("input");
    fieldS.type = "submit";
    fieldS.name = "ogone_submit";
    fieldS.value = "Submit";
    fieldS.style.display = "none";

    return fieldS;
  }

  //endregion
}
