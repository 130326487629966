import Button, { ButtonType } from "components/Button";
import CheckIcon from "components/Icons/Check";
import DeleteIcon from "components/Icons/Delete";
import DeviceTvIcon from "components/Icons/DeviceTV";
import DeviceTvError from "components/Icons/DeviceTvError";
import Input, { InputType } from "components/Input";
import Modal from "components/Modal";
import consts from "consts/consts";
import { getFromLocal } from "helpers/localStorage";
import React, { Component, Fragment } from "react";
import { Trans, withTranslation } from "react-i18next";

import style from "./style.module.css";

class DevicesManager extends Component {
  state = {
    showConfirmModal: false,
    indexToDelete: null,
    deleteCurrentDevice: false,
    showAddDeviceModal: false,
    pairingCode: "",
    showLimitDeviceErrorModal: false,
    loginPairingIsFeatching: false,
    pairingErrorMsg: "",
    showPairingDeviceSuccessModal: false,
  };

  handleOnDelete(index) {
    const currentDeviceId = getFromLocal(consts.storageKeys.device);
    const deviceId = this.props.devices[index].getUniqueDeviceId();

    let deleteCurrentDevice = false;

    if (currentDeviceId === deviceId) {
      deleteCurrentDevice = true;
    }

    this.setState({
      indexToDelete: index,
      deleteCurrentDevice: deleteCurrentDevice,
    });
    this.showModal("showConfirmModal");
  }

  showModal(modalType) {
    this.setState({ [modalType]: true });
  }

  hideModal(modalType) {
    this.setState({ [modalType]: false, pairingErrorMsg: "", pairingCode: "" });
  }

  onChangepairingCode = (value) => {
    this.setState({ pairingCode: value.toUpperCase() });
  };

  checkNumberDeviceBeforePiring = () => {
    const { deviceCounter } = this.props;
    let modalToShow = "showAddDeviceModal";
    if (
      deviceCounter &&
      deviceCounter.numberOfDevices >= deviceCounter.maxNumberOfDevices
    ) {
      modalToShow = "showLimitDeviceErrorModal";
    }
    this.showModal(modalToShow);
  };

  onValidatePairingCode = async () => {
    const { onLoginDevicePairing } = this.props;
    const { pairingCode } = this.state;
    const currentDeviceId = getFromLocal(consts.storageKeys.device);
    const errorCodeList = [-1, -10, -11, 500, -429, -1074];
    let updatedState = {};
    if (pairingCode) {
      this.setState({ loginPairingIsFeatching: true });
      const result = await onLoginDevicePairing(
        pairingCode.toLocaleLowerCase(),
        currentDeviceId
      );
      if (result?.code && errorCodeList.includes(result.code)) {
        updatedState.pairingErrorMsg = result.message;
      } else {
        updatedState.showPairingDeviceSuccessModal = true;
        updatedState.showAddDeviceModal = false;
        updatedState.pairingCode = "";
      }
      this.setState({ loginPairingIsFeatching: false, ...updatedState });
    }
  };

  onFinishPairingProcess = () => {
    const { getDevicesList } = this.props;

    getDevicesList();
    this.setState({
      showPairingDeviceSuccessModal: false,
    });
  };

  async componentDidMount() {
    const search = new URLSearchParams(window.location.search);
    const pairing = search.get("pairing") || false;
    const pairingCode = search.get("code")?.toUpperCase() || "";
    const newState = {
      pairingCode,
    };

    if (consts.pairingEnabled) newState.showAddDeviceModal = pairing;
    this.setState({ ...newState }, () => {
      if (consts.pairingEnabled && pairingCode) this.onValidatePairingCode();
    });
  }

  render() {
    const { devices, deviceCounter, onDeleteClick, showMessage, t } =
      this.props;
    const {
      showConfirmModal,
      indexToDelete,
      deleteCurrentDevice,
      showAddDeviceModal,
      pairingCode,
      showLimitDeviceErrorModal,
      loginPairingIsFeatching,
      pairingErrorMsg,
      showPairingDeviceSuccessModal,
    } = this.state;

    let titleModal = "";
    if (!deleteCurrentDevice) {
      titleModal = t("You are about to remove a device");
    } else {
      if (deleteCurrentDevice && deleteCurrentDevice === true) {
        titleModal = t("You are about to remove your current device");
      }
    }

    return (
      <Fragment>
        {deviceCounter && (
          <Fragment>
            <div className={style.countContainer}>
              <div className={style.DevicesManagerCard}>
                <div className={style.title}>
                  <Trans>You have currently</Trans>
                </div>
                <div className={style.number}>
                  {deviceCounter.numberOfDevices}
                </div>
                <div className={style.title}>
                  <Trans>devices connected</Trans>
                </div>
              </div>
              <div className={style.DevicesManagerCard}>
                <div className={style.title}>
                  <Trans>You can connect</Trans>
                </div>
                <div className={style.number}>
                  {deviceCounter.maxNumberOfDevices}
                </div>
                <div className={style.title}>
                  <Trans>devices maximum</Trans>
                </div>
              </div>
            </div>
            {showMessage && (
              <div className={style.infoContainer}>
                <p className={style.info}>
                  {t(
                    "Remove an associated device to access the application on this device"
                  )}
                </p>
              </div>
            )}
          </Fragment>
        )}

        {/* pairing manager */}
        {consts.pairingEnabled && (
          <div className={style.pairingSection}>
            <div className={style.pairingText}>
              <p className={style.pairingTitle}>
                <Trans>Add a new TV device</Trans>
              </p>
              <p className={style.pairingDesc}>
                <Trans>
                  Once added you will be automatically logged on your TV device
                </Trans>
              </p>
            </div>
            <div className={style.pairingAddButton}>
              <Button
                rootClassName={style.addButton}
                type={ButtonType.NORMAL}
                onClick={this.checkNumberDeviceBeforePiring.bind(this)}
              >
                <Trans>Add a TV device</Trans>
              </Button>
            </div>
          </div>
        )}
        {consts.pairingEnabled && (
          <div className={style.devicesListTitle}>
            <p className={style.devicesTitle}>
              <Trans>My connected devices</Trans>
            </p>
          </div>
        )}
        {devices &&
          devices.map((item, index) => (
            <div className={style.deviceContainer} key={index}>
              <div className={style.deviceName}>{item.getName()}</div>
              <div className={style.lastConnexion}>
                <Trans>Last Connexion</Trans> : {item.getLastLoginDate()}
              </div>
              <DeleteIcon
                className={style.deleteIcon}
                onClick={this.handleOnDelete.bind(this, index)}
              />
            </div>
          ))}
        {showConfirmModal && (
          <Modal
            leftContent={{ title: titleModal }}
            rightContent={{
              canDismiss: this.hideModal.bind(this, "showConfirmModal"),
            }}
            rootClassName={style.modal}
            overlay={true}
            buttons={
              <>
                <span
                  onClick={this.hideModal.bind(this, "showConfirmModal")}
                  className={style.cancelButton}
                >
                  <Trans>CANCEL</Trans>
                </span>
                <span
                  onClick={onDeleteClick.bind(this, indexToDelete)}
                  className={style.confirmButton}
                >
                  <Trans>REMOVE THIS DEVICE</Trans>
                </span>
              </>
            }
          >
            {!deleteCurrentDevice && (
              <div>
                <p className={style.message}>
                  <Trans>Your account will be disconnected from</Trans>{" "}
                  <span className={style.deviceNameToDelete}>
                    {this.props.devices[indexToDelete].name}
                  </span>
                </p>
              </div>
            )}
            {deleteCurrentDevice && deleteCurrentDevice === true && (
              <div>
                <p className={style.message}>
                  <Trans>By removing</Trans>{" "}
                  <span className={style.deviceNameToDelete}>
                    {this.props.devices[indexToDelete].name}
                  </span>{" "}
                  <Trans>
                    you will be disconnected and redirected to the login page
                  </Trans>
                </p>
              </div>
            )}
          </Modal>
        )}
        {/* pairing modal */}
        {showAddDeviceModal && (
          <Modal
            leftContent={{ title: t("Add a new TV device") }}
            rightContent={{
              canDismiss: this.hideModal.bind(this, "showAddDeviceModal"),
            }}
            rootClassName={style.addDeviceModal}
            overlay={true}
          >
            <div className={style.addDeviceModalBody}>
              <DeviceTvIcon className={style.deviceTvIcon} />
              <p className={style.addDeviceDesc}>
                <Trans>
                  Please enter and validate the code displayed on your TV screen
                  bellow to log in your TV device
                </Trans>
              </p>
              <Input
                rootClassName={style.pairingCodeInput}
                type={InputType.TEXT}
                placeholder={t("Code")}
                onChange={(value) => this.onChangepairingCode(value)}
                value={pairingCode}
              />
              {pairingErrorMsg && (
                <p className={style.pairingErrorMsg}>{pairingErrorMsg}</p>
              )}
              <Button
                rootClassName={
                  pairingErrorMsg
                    ? style.validatePairingCodeWithError
                    : style.validatePairingCode
                }
                type={
                  loginPairingIsFeatching
                    ? ButtonType.LOADING
                    : ButtonType.NORMAL
                }
                onClick={this.onValidatePairingCode.bind(this)}
              >
                <Trans>Validate</Trans>
              </Button>
            </div>
          </Modal>
        )}
        {/* pairing modal Error limit reached */}
        {showLimitDeviceErrorModal && (
          <Modal
            leftContent={{ title: t("Add a new TV device") }}
            rightContent={{
              canDismiss: this.hideModal.bind(
                this,
                "showLimitDeviceErrorModal"
              ),
            }}
            rootClassName={style.addDeviceErrorModal}
            overlay={true}
          >
            <div className={style.addDeviceModalBody}>
              <DeviceTvError className={style.deviceTvIcon} />
              <p className={style.addDeviceDesc}>
                <Trans>
                  You have reached your maximum device limit. Please remove a
                  connected device before adding a new one
                </Trans>
              </p>

              <Button
                rootClassName={style.validatePairingCode}
                type={ButtonType.NORMAL}
                onClick={this.hideModal.bind(this, "showLimitDeviceErrorModal")}
              >
                <Trans>I understand</Trans>
              </Button>
            </div>
          </Modal>
        )}
        {/* pairing modal Success */}
        {showPairingDeviceSuccessModal && (
          <Modal
            leftContent={{ title: t("Add a new TV device") }}
            rightContent={{
              canDismiss: this.onFinishPairingProcess.bind(this),
            }}
            rootClassName={style.pairingModalSuccess}
            overlay={true}
          >
            <div className={style.addDeviceModalBody}>
              <CheckIcon className={style.checkIcon} />
              <p className={style.addDeviceDesc}>
                <Trans>
                  Your TV device has been successfully added to your account
                </Trans>
              </p>

              <Button
                rootClassName={style.pairingSuccessButton}
                type={ButtonType.NORMAL}
                onClick={this.onFinishPairingProcess.bind(this)}
              >
                <Trans>Ok</Trans>
              </Button>
            </div>
          </Modal>
        )}
      </Fragment>
    );
  }
}

export default withTranslation()(DevicesManager);
