import { addToast, setModalNPVRInfo } from "actions/ui";
import classnames from "classnames";
import Button, { ButtonType } from "components/Button";
import CloseIcon from "components/Icons/Close";
import consts from "consts/consts";
import { getFromLocal } from "helpers/localStorage";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Trans, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose } from "redux";
import { TucanoActions } from "web-api/main";

import style from "./style.module.css";
class ModalNPVRRecord extends Component {
  componentDidMount() {
    if (this.props.overlay) {
      document.body.style.top = "0";
      document.body.style.bottom = "0";
      document.body.style.left = "0";
      document.body.style.right = "0";
    }
    document.addEventListener("keydown", this.handleKeyPressed, false);
  }

  componentWillUnmount() {
    if (this.props.overlay) {
      document.body.style.position = null;
      document.body.style.top = null;
      document.body.style.bottom = null;
      document.body.style.left = null;
      document.body.style.right = null;
    }

    document.removeEventListener("keydown", this.handleKeyPressed, false);
  }
  closeModal() {
    this.props.dispatch(
      setModalNPVRInfo({
        showModalDeleteNPVR: false,
        idRecord: "",
        isQuotaExceeded: false,
      })
    );
  }
  handleOverlayClick() {
    this.closeModal();
  }
  deleteNPVRRecord() {
    const { dispatch, t, modalNPVRInfo } = this.props;
    dispatch(TucanoActions.deleteRecord(modalNPVRInfo?.idRecord))
      .then(async (result) => {
        if (result.newAuthToken) {
          this.closeModal();
          this.props.dispatch(
            addToast({
              text: t("Your record has been successfully deleted"),
              className: "primary",
              duration: 6000,
            })
          );
          await dispatch(TucanoActions.getNPVRRecords());
          await dispatch(TucanoActions.getNpvrQuota());
          if (this.props.refreshPrograms) {
            this.props.refreshPrograms();
          }
        } else if (result.code) {
          this.props.dispatch(
            addToast({
              text: t(this.getErrorAPI(result.code)),
              className: "error",
              duration: 6000,
            })
          );
        }
      })
      .catch((_err) => {
        this.props.dispatch(
          addToast({
            text: t(this.getErrorAPI()),
            className: "error",
            duration: 6000,
          })
        );
      });
  }

  renderParagraphModal() {
    const { modalNPVRInfo, t } = this.props;
    if (modalNPVRInfo?.npvrChannel == -1 && consts.appModules.subscription) {
      return t(
        "You are not authorized to record this asset. Please subscribe to an offer including the record option."
      );
    } else if (
      modalNPVRInfo?.npvrChannel == -1 &&
      !consts.appModules.subscription
    ) {
      return t(
        "You are not authorized to record this asset. Please contact your service provider."
      );
    } else if (
      modalNPVRInfo?.npvrChannel >= 0 &&
      modalNPVRInfo?.isQuotaExceeded &&
      !consts.appModules.subscription
    ) {
      return t(
        "You must free up recording space by deleting some of your recordings or upgrade your subscription by contacting your service provider."
      );
    } else if (
      modalNPVRInfo?.npvrChannel >= 0 &&
      modalNPVRInfo?.isQuotaExceeded &&
      consts.appModules.subscription
    ) {
      return t(
        "You must free up recording space by deleting some of your recordings or subscribing to an offer that support record."
      );
    } else {
      return "";
    }
  }

  render() {
    const { rootClassName, t, modalNPVRInfo } = this.props;
    const finalClassName = classnames(rootClassName, style.modalMain, {
      [style.modalMainLg]: modalNPVRInfo?.isQuotaExceeded,
    });
    const direction = getFromLocal("layoutDirection");
    return (
      <div className={classnames(style.root, style.withOverlay)}>
        <div className={classnames(style.modal, style.animation)}>
          <div className={finalClassName}>
            <div
              className={classnames(style.header, {
                [style.headerRLT]: direction.toLowerCase() === "rtl",
              })}
            >
              <CloseIcon
                className={style.iconClose}
                onClick={this.closeModal.bind(this)}
              />
            </div>
            <div className={classnames(style.content)}>
              {modalNPVRInfo?.npvrChannel >= 0 && (
                <>
                  <h4>
                    {modalNPVRInfo?.isQuotaExceeded
                      ? t("You have reached the limit of your recording quota")
                      : t(
                          `Are you sure you want to cancel the ${modalNPVRInfo?.typeRecordButton} ?`
                        )}
                  </h4>
                  <hr />
                </>
              )}
              {this.renderParagraphModal()}
              <div className={style.actionButtonsModal}>
                {consts.appModules.subscription &&
                (modalNPVRInfo?.isQuotaExceeded ||
                  modalNPVRInfo?.npvrChannel == -1) ? (
                  <>
                    <Button
                      rootClassName={style.button}
                      type={ButtonType.BORDERED}
                      onClick={this.closeModal.bind(this)}
                    >
                      <Trans t={t}>Cancel</Trans>
                    </Button>

                    <Button
                      rootClassName={style.button}
                      type={ButtonType.NORMAL}
                      onClick={() =>
                        this.props.history.push(consts.routes.subscription.url)
                      }
                    >
                      <Trans t={t}>See Offers</Trans>
                    </Button>
                  </>
                ) : !consts.appModules.subscription &&
                  (modalNPVRInfo?.isQuotaExceeded ||
                    modalNPVRInfo?.npvrChannel == -1) ? (
                  <>
                    <Button
                      rootClassName={style.button}
                      type={ButtonType.BORDERED}
                      onClick={this.closeModal.bind(this)}
                    >
                      <Trans t={t}>Ok</Trans>
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      rootClassName={style.button}
                      type={ButtonType.BORDERED}
                      onClick={() => this.deleteNPVRRecord()}
                    >
                      {["Recording", "Planned"].includes(
                        modalNPVRInfo?.typeRecordButton
                      ) ? (
                        <Trans t={t}>Cancel</Trans>
                      ) : (
                        <Trans t={t}>Delete</Trans>
                      )}
                    </Button>
                    <Button
                      rootClassName={style.button}
                      type={ButtonType.NORMAL}
                      onClick={this.closeModal.bind(this)}
                    >
                      <Trans t={t}>
                        Keep {modalNPVRInfo?.typeRecordButton}
                      </Trans>
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>
          <div
            className={style.overlay}
            onClick={this.handleOverlayClick.bind(this)}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(
  compose(
    connect((state, _) => {
      return {
        modalNPVRInfo: state.ui.modalNPVRInfo,
      };
    }),
    withTranslation()
  )(ModalNPVRRecord)
);

ModalNPVRRecord.defaultProps = {
  overlay: false,
};

ModalNPVRRecord.propTypes = {
  overlay: PropTypes.bool,
};
